<template>
    <v-container fluid class="customersContainer">
        <v-row no-gutters align="center" justify="center">
            <v-col no-gutters align="center">

                <!-- COMPANY GROUP DESCRIPTION -->
                <v-row class="px-16 mx-16 mt-8">
                    <p class="centuryGothic px-0 px-md-16 mx-0 mx-md-16">
                        Como Grupo, <b>Calidra</b> cuenta con varias plantas y puntos de distribución a lo largo de la
                        República
                        Mexicana y en los últimos años hemos crecido hacia el sur del continente, teniendo presencia en
                        Honduras, Perú, Argentina y Colombia.
                    </p>
                </v-row>

                <!-- COMPANY IN NUMBERS -->
                <v-row align="center" class="mt-8 px-16 mb-8 py-4" justify="center" style="background-color: #EE403D;">
                    <v-col cols="6" md="3" lg="3" xl="3">
                        <p class="centuryGothic screenTitle white--text">1</p>
                        <p class="centuryGothicBold white--text">SCMB</p>
                    </v-col>
                    <v-col cols="6" md="3" lg="3" xl="3">
                        <p class="centuryGothic screenTitle white--text">8</p>
                        <p class="centuryGothicBold white--text">PAÍSES</p>
                    </v-col>
                    <v-col cols="6" md="3" lg="3" xl="3">
                        <p class="centuryGothic screenTitle white--text">25+</p>
                        <p class="centuryGothicBold white--text">PRODUCTOS</p>
                    </v-col>
                    <v-col cols="6" md="3" lg="3" xl="3">
                        <p class="centuryGothicBold white--text">Y VAMOS POR <br> MÁS...</p>
                    </v-col>
                </v-row>

                <!-- COMPANY CUSTOMERS -->
                <v-row class="mx-16 px-16 mb-16" align="center" justify="center">
                    <v-col align="center">
                        <p class="centuryGothicBold screenTitle mt-4">ALGUNOS DE NUESTROS CLIENTES</p>

                        <v-row align="center" justify="center">
                            <v-col v-for="(logo, index) in customersLogos" :key="index" cols="3" md="3" lg="1" xl="1">
                                <v-img
                                    :src="require(`../../assets/customers/${logo}`)"
                                    transition="scale-transition"
                                ></v-img>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "Customers_Section",
    data() {
        return {
            customersLogos: [
                'carso.png',
                'ivi.png',
                'aisa.png',
                'arquitectoma.png',
                'gdc.png',
                'origina.png',
                'ficade.png',
                'imss.png',
                'zd+a.png',
                'carpenti.png',
                'sadasi.png',
                'vinte.png',
                'bosquereal.png',
                'arpada.png',
                'upaep.png',
                'mx.png',
                'sedena.png',
                'dosa.png',
                'constructor.png',
                'fine.png',
                'platea.png',
                'quiero.png',
                'sacyr.png',
                'jv.png',
                'las moras.png',
                'pramvala.png',
                'ruba.png',
                'segovia.png',
                'bosques.png',
                'sta.fe.png',
                'novaceramic.png',
                'buap.png',
                'aym.png',
                'boudica.png',
                'cadu.png',
            ]
        }
    }
}
</script>

<style scoped>
.customersContainer {
    padding: 50px 0 0 0 !important;
}

@media (max-width: 960px) {
    .customersContainer {
        padding: 50px 0 0 0 !important;
    }

}
</style>