<template>
    <v-row align="center" class="noSpace" justify="center">
        <v-col align="center" class="noSpace">
            <!-- SECTION TITLE -->
            <v-row align="center" class="noSpace" justify="center">
                <p class="centuryGothicBold screenTitle mt-16">MEZCLA BRAVA</p>
            </v-row>

            <!-- BRAND DESCRIPTION -->
            <v-row align="center" class="noSpace px-12" justify="center">
                <p class="centuryGothic">
                    Son productos con mucha tecnología, con excelentes ventajas y beneficios, que aseguran y garantizan la calidad que requiere su utilización.
                </p>

                <p class="centuryGothic">
                    <b>Mezcla Brava</b> nace en respuesta a las necesidades de la industria de la construcción.
                    Forma parte de <a
                    href="https://www.calidra.com/"
                    target="_blank"
                    style="color: #e6413d !important;"
                    class="centuryGothicBold"
                ><b>Grupo Calidra</b></a>, líder en la Industria de la Cal de Construcción en Latinoamérica,
                    con más de un siglo de historia en México.

                </p>
            </v-row>

            <!-- SUBTITLE SECTION -->
            <v-row align="center" class="noSpace" justify="center">
                <p class="centuryGothicBold screenTitle mt-8 px-4" style="color: #EE403D;">¡CONSTRUYE RÁPIDO FÁCIL Y BIEN! con</p>
            </v-row>

            <!-- SUBTITLE IMAGE TITLE -->
            <v-row align="center" class="noSpace" justify="center">
                <v-col cols="8" md="3">
                    <v-img
                        src="../../assets/icons/Soluciones_Calidra.svg"
                    ></v-img>
                </v-col>
            </v-row>

            <!-- CARD ROW -->
            <v-row v-for="(card, index) in cardsList" :key="index" align="start" class="cardsRow mt-14 noSpace"
                   justify="start">
                <!-- EMPTY SPACE -->
                <v-col v-if="card.side === false" class="pa-0 hidden-md-and-down" cols="4"></v-col>

                <!-- CARD SPACE -->
                <v-col class="pa-0" cols="12" lg="8" xl="8">
                    <v-container>
                        <v-row align="center" justify="center">
                            <!-- MOBILE IMAGE COLUMN -->
                            <v-col class="pa-0 hidden-lg-and-up" cols="9" lg="6" xl="6">
                                <v-img
                                    :class="card.side === false ? 'rightCardImage' : 'leftCardImage'"
                                    :src="require(`../../assets/mb/${card.image}`)"
                                    height="35vh"
                                    transition="scale-transition"
                                    width="35vh"
                                ></v-img>
                            </v-col>

                            <!-- IMAGE COLUMN -->
                            <v-col v-if="card.side === false" class="pa-0 hidden-md-and-down" cols="8" lg="6" xl="6">
                                <v-img
                                    :class="card.side === false ? 'rightCardImage' : 'leftCardImage'"
                                    :src="require(`../../assets/mb/${card.image}`)"
                                    height="43vh"
                                    transition="scale-transition"
                                    width="43vh"
                                ></v-img>
                            </v-col>

                            <!-- TEXT COLUMN -->
                            <v-col class="pa-0" cols="12" lg="6" xl="6" align-self="center">
                                <v-container
                                    :class="card.side === false ? 'rightCardTextContainer' : 'leftCardTextContainer'"
                                    align-self="center"
                                >
                                    <p class="centuryGothic white--text cardText">
                                        {{ card.text }}
                                    </p>
                                </v-container>
                            </v-col>

                            <!-- IMAGE COLUMN -->
                            <v-col v-if="card.side === true" class="pa-0 hidden-md-and-down" cols="8" lg="6" xl="6">
                                <v-img
                                    :class="card.side === false ? 'rightCardImage' : 'leftCardImage'"
                                    :src="require(`../../assets/mb/${card.image}`)"
                                    height="43vh"
                                    transition="scale-transition"
                                    width="43vh"
                                ></v-img>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>

                <!-- EMPTY SPACE -->
                <v-col v-if="card.side === true" class="pa-0 hidden-md-and-down" cols="4"></v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "AboutMezclaBrava_Section",
    data() {
        return {
            cardsList: [
                {
                    // side: true --> left card ; side: false --> right card
                    side: true,
                    text: 'MEZCLA BRAVA busca atender las necesidades de la industria de la construcción, la cual demanda materiales que permitan construir a mayor velocidad, con costos más bajos y una calidad garantizada.',
                    image: 'mezclas_para_construccion.png',
                },
                {
                    // side: true --> left card ; side: false --> right card
                    side: false,
                    text: 'Desarrollamos Mezclas Listas compuestas por uno o más conglomerantes y agregados áridos controlados. Pueden tener aditivos y/o adiciones predosificadas en fábrica que dan como resultado polvos, a los cuales solo hay que agregar agua y están listos para usarse.',
                    image: 'agregar_agua.png',
                },
                {
                    // side: true --> left card ; side: false --> right card
                    side: true,
                    text: 'Se suministran en sacos o en silo y se amasan en la obra con el agua indicada hasta obtener una mezcla homogénea para un propósito específico.',
                    image: 'materiales_en_saco_y_en_silo.png',
                },
                {
                    // side: true --> left card ; side: false --> right card
                    side: false,
                    text: 'Se tienen productos para cada etapa de una obra de construcción. Los objetivos principales son reducir tiempos, mejorar la calidad, simplificar la logística, reducir desperdicios y sobre todo, tener un ahorro económico.',
                    image: 'ahorro_en_construccion.png',
                },
                {
                    // side: true --> left card ; side: false --> right card
                    side: true,
                    text: 'La alta tecnología detrás de las Mezclas Listas permite satisfacer las exigencias de proyectistas y constructores; tanto en obra, como en sus requerimientos constructivos.',
                    image: 'sistema_constructivo.png',
                }
            ]
        }
    }
}
</script>

<style scoped>
a {
    color: #3D3533 !important;
}

a:hover {
    color: #e6413d !important;
}

.leftCardImage {
    border-radius: 150px;
    position: relative;
}

.rightCardImage {
    border-radius: 150px;
    position: relative;
}

.leftCardTextContainer {
    background-color: #EE403D;
    border-bottom-left-radius: 150px;
    border-top-left-radius: 150px;
    padding: 30px 50px 10px 40px;
    margin-left: 88px;
    min-height: 25vh;
}

.rightCardTextContainer {
    background-color: #3D3533;
    border-bottom-right-radius: 150px;
    border-top-right-radius: 150px;
    padding: 30px 40px 10px 50px;
    margin-left: -88px;
    min-height: 25vh;
}

.cardsRow {
    padding: 0 50px !important;
    margin-bottom: 50px !important;
}

.cardText {
    font-size: 0.85em;
}

@media (max-width: 960px) {
    .leftCardImage {
        border-radius: 150px;
        position: relative;
        width: 100%;
        height: 100%;
    }

    .rightCardImage {
        border-radius: 150px;
        position: relative;
        border: 20px solid #3D3533;
        outline: none;
    }

    .leftCardTextContainer {
        background-color: #e6413d;
        border-bottom-left-radius: 150px;
        border-top-left-radius: 150px;
        padding: 30px 30px 10px 40px;
        margin-left: 10px;
        margin-top: 40px;
        min-height: auto;
    }

    .rightCardTextContainer {
        background-color: #3D3533;
        border-bottom-right-radius: 150px;
        border-top-right-radius: 150px;
        padding: 30px 40px 10px 20px;
        margin-left: -10px;
        margin-top: 40px;
        min-height: auto;
    }

    .cardsRow {
        padding: 0 10px !important;
        margin-bottom: 50px !important;
    }

    .cardText {
        font-size: 0.9em;
    }
}

@media (max-width: 1264px) and (min-width: 961px) {
    .leftCardTextContainer {
        background-color: #e6413d;
        border-radius: 150px;
        padding: 30px 50px 10px 40px;
        margin: 50px 0 0 0 !important;
        min-height: auto;
    }

    .rightCardTextContainer {
        background-color: #3D3533;
        border-radius: 150px;
        padding: 30px 50px 10px 40px;
        margin: 50px 0 0 0 !important;
        min-height: auto;
    }
}

@media (min-width: 1904px) {
    .leftCardImage {
        border-radius: 150px;
        position: relative;
        outline: 20px solid #e6413d;
        border: 5px solid #3D3533;
    }

    .rightCardImage {
        border-radius: 150px;
        position: relative;
        outline: 20px solid #3D3533;
        border: 5px solid #e6413d;
    }

    .leftCardTextContainer {
        background-color: #e6413d;
        border-bottom-left-radius: 150px;
        border-top-left-radius: 150px;
        padding: 30px 30px 10px 40px;
        margin-top: 40px;
        min-height: 18vh;
    }

    .rightCardTextContainer {
        background-color: #3D3533;
        border-bottom-right-radius: 150px;
        border-top-right-radius: 150px;
        padding: 30px 40px 10px 30px;
        margin-top: 40px;
        min-height: 18vh;
    }

    .cardsRow {
        margin-bottom: 80px !important;
        padding: 0 200px !important;
    }

    .cardText {
        font-size: 0.9em;
    }
}
</style>