<template>
    <v-container class="pa-0" fluid>
        <!-- PAGE JUMBOTRON -->
        <ImageJumbotron_Section
            :img-path="'nosotros_somos_mezcla_brava.png'"
            :text="'CONÓCENOS'"
        ></ImageJumbotron_Section>

        <!-- MEZCLA BRAVA INFO -->
        <AboutMezclaBrava_Section></AboutMezclaBrava_Section>

        <!-- BENEFITS SECTION -->
        <Benefits_Section :color="false"></Benefits_Section>

        <!-- CUSTOMERS SECTION -->
        <Customers_Section></Customers_Section>

        <!-- SUCCESS STORIES SECTION -->
        <SuccessStories_Section></SuccessStories_Section>
    </v-container>
</template>

<script>
import ImageJumbotron_Section from "../../components/sections/ImageJumbotron_Section";
import AboutMezclaBrava_Section from "../../components/sections/AboutMezclaBrava_Section";
import Benefits_Section from "../../components/sections/Benefits_Section";
import Customers_Section from "../../components/sections/Customers_Section";
import SuccessStories_Section from "../../components/sections/SuccessStories_Section";

export default {
    name: "AboutUs",
    components: {
        ImageJumbotron_Section,
        AboutMezclaBrava_Section,
        Benefits_Section,
        Customers_Section,
        SuccessStories_Section,
    },
    metaInfo() {
        return {
            title: 'Mezcla Brava | Nosotros',
            meta: [
                { vmid: 'description', name: 'description', content: 'Mezcla Brava es parte de la gran familia de GRUPO CALIDRA; la empresa con mayor tradición en la industria de la construcción y orgullosamente mexicanos.' },
                { vmid: 'og:image', name: 'og:image', content: 'https://firebasestorage.googleapis.com/v0/b/mezcla-brava.appspot.com/o/Logo_MezclaBrava_Slogan.png?alt=media&token=ad5e4faf-e91f-48b8-8b75-8eeb204328e2' },
                { vmid: 'og:image', name: 'og:image', content: 'https://firebasestorage.googleapis.com/v0/b/mezcla-brava.appspot.com/o/Logo_MezclaBrava_Slogan.png?alt=media&token=ad5e4faf-e91f-48b8-8b75-8eeb204328e2' },
                { vmid: 'twitter:image', name: 'twitter:image', content: 'https://firebasestorage.googleapis.com/v0/b/mezcla-brava.appspot.com/o/Logo_MezclaBrava_Slogan.png?alt=media&token=ad5e4faf-e91f-48b8-8b75-8eeb204328e2' },
                { vmid: 'og:url', name: 'og:url', content: 'https://www.mezclabrava.com/' },
                { vmid: 'twitter:url', name: 'twitter:url', content: 'https://www.mezclabrava.com/' },
            ]
        }
    },
}
</script>

<style scoped>
</style>